import React, {useEffect, useState} from 'react';
import '../../components/myStyles.css';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import statsperson from '../../components/Images/stats-person.png';
import calendarimage from '../../components/Images/calendar.png';
import clock from '../../components/Images/Vector.png';
import arrowdown from '../../components/Images/arrow-down.png';
import Header from '../../components/Header';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import Table from 'react-bootstrap/Table';
import "antd/dist/antd.css";
import { Modal } from 'antd';
import dashboard from '../../components/Images/dashboard.png';
import patients from '../../components/Images/patients.png';
import notes from '../../components/Images/notes.png';
import wallet from '../../components/Images/wallet.png';
import logo from '../../images/dashboard-logo.jpg';
import withdraw from '../../images/withdraw.png';
import {NavLink} from 'react-router-dom';
import {Nav} from 'react-bootstrap';
import logout from '../../images/logout.png';
import lock from '../../images/lock.png'
import TawkToChat from '../../components/Chatbot';


function Dashboard() {

    const [user, setUser] = useState([]);
    const [plans, setPlans] = useState({});
    const [portfolio, setPortfolio] = useState({});
    const [isWalletModalVisible, setIsWalletModalVisible] = useState(false);
    const [portfolioLength, setPortfolioLength] = useState(0);
    const [balance, setUserBalance] = useState(0);
    const [withdrawals, setWithdrawals] = useState({});
    const [isWithdrawalModalVisible, setIsWithdrawalModalVisible] = useState(false);
    const [amount, setAmount] = useState(0);
    const [totalPortfolio, setTotalPortfolio] = useState(0);
    const [deposit, setDeposit] = useState({});
    const[isVerifyModalVisible, setIsVerifyModalVisible] = useState(false)
    const[idFrontImage, setIdFrontImage] = useState('')
    const[idBackImage, setIdBackImage] = useState('')
    const [amountInvestedArray, setAmountInvestedArray] = useState([])

    const handleIdFrontChange = (event) => {
        if (event.target.files[0]) {
          const selectedImage = event.target.files[0]
          setIdFrontImage(selectedImage)
        }
    }

    const handleIdBackChange = (event) => {
        if (event.target.files[0]) {
          const selectedImage = event.target.files[0]
          setIdBackImage(selectedImage)
        }
    }

    const backIdUpload = async () => {
        try {
            const uploadTaskSnapshot = await firebase.storage().ref(`/images/${idBackImage.name}`).put(idBackImage);
            // Get the download URL after the upload is complete
            const idBackFirebaseUrl = await uploadTaskSnapshot.ref.getDownloadURL();
            return idBackFirebaseUrl;
        } catch (error) {
            console.error('Error uploading or getting download URL for ID Back:', error);
            throw error; // Rethrow the error for handling in the calling function
        }
    }
    
    const submitVerification = async (event) => {
        event.preventDefault();
        try {
            const uploadTaskSnapshot = await firebase.storage().ref(`/images/${idFrontImage.name}`).put(idFrontImage);
            // Get the download URL after the upload is complete
            const idFrontFirebaseUrl = await uploadTaskSnapshot.ref.getDownloadURL();
            
            // Upload ID Back and get its URL
            const idBackFirebaseUrl = await backIdUpload();
    
            // Continue with saving data
            saveVerificationData(idFrontFirebaseUrl, idBackFirebaseUrl);
        } catch (error) {
            console.error('Error uploading or getting download URL for ID Front:', error);
        }
    }
    

    const saveVerificationData = (idFrontFirebaseUrl, idBackFirebaseUrl) => {
        firebase.firestore()
            .collection('users')
            .doc(firebase.auth().currentUser.uid)
            .update({
                verified: true,
                idFrontFirebaseUrl, 
                idBackFirebaseUrl
            })
            .then(() => {
                alert('Verification complete')
                setIsVerifyModalVisible(false)
            })
            .catch(error => {
                console.error(error)
            })
    }

    const db = firebase.firestore();

    const fetchData = () => {
        firebase.firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then((snapshot) => {
            if(snapshot.exists){
                console.log(snapshot.data());
                setUser(snapshot.data());
                //console.log(user);
                setUserBalance(user.balance)
            } else {
                console.log('does not exist')
            }
        })

        const plan = []
        const plansAmountArray = []
        const planRef = db.collection('plans');
        const planssnapshot = planRef.get();
        planssnapshot.then(function(querySnapshot) {
            querySnapshot.forEach(doc => {
                //console.log(doc.data())
                const planns = {name: doc.id, duration: doc.data().duration, dailyroi: doc.data().dailyroi, weeklyroi: doc.data().weeklyroi, mininvestment: doc.data().mininvestment};
                //console.log('damn', planns)
                plan.push(planns);
                plansAmountArray.push(doc.data().mininvestment)
                //console.log(plan);
                setPlans(plan);
                //console.log('yo', plan);
            })
            setAmountInvestedArray(plansAmountArray)
        });

        const portfolio = []
        let initial = 0
        firebase.firestore()
        .collection('portfolio')
        .doc(firebase.auth().currentUser.uid)
        .collection('userPortfolio')
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(doc => {
                //console.log('Uhm', doc.data())
                const portfolios = {name: doc.id, duration: doc.data().duration, dailyroi: doc.data().dailyroi, weeklyroi: doc.data().weeklyroi, mininvestment: doc.data().mininvestment, currentDate: doc.data().currentDate, status: doc.data().status};
                initial = initial + portfolios.mininvestment
                setTotalPortfolio(initial)
                portfolio.push(portfolios);
                setPortfolio(portfolio);
                setPortfolioLength(Object.keys(portfolio).length);
            })
        });

        const withdrawal = []
        firebase.firestore()
        .collection('withdrawal')
        .doc(firebase.auth().currentUser.uid)
        .collection('userWithdraw')
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(doc => {
                //console.log('Uhm', doc.data())
                const withdrawals = {amount: doc.data().amount, date: doc.data().time}
                withdrawal.push(withdrawals)
                setWithdrawals(withdrawal)
            })
        });

        const deposit = []
        firebase.firestore()
        .collection('deposit')
        .doc(firebase.auth().currentUser.uid)
        .collection('userDeposit')
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(doc => {
                //console.log('Uhm', doc.data())
                const deposits = {amount: doc.data().amount, date: doc.data().time}
                deposit.push(deposits)
                setDeposit(deposit)
            })
        });
    }

    useEffect(() => {
        fetchData()
        setTimeout(() => firebase.auth().signOut(), 
            3600 * 1000
        );
    }, [amount, balance, db, user.balance]);

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    //console.log(currentDate);

    const addToPortfolio = (name, duration, dailyroi, weeklyroi, mininvestment) => {
        if(balance >= mininvestment) {
            const check = []
            Object.entries(portfolio).map(([key, value]) => {
                check.push(value.name);
                return(0)
            })
            if(check.includes(name)) {
                alert('You have already purchased this plan')
            } else {
                firebase.firestore().collection('portfolio')
                .doc(firebase.auth().currentUser.uid)
                .collection('userPortfolio')
                .doc(name)
                .set({
                    name,
                    duration,
                    dailyroi,
                    weeklyroi,
                    mininvestment: parseInt(mininvestment),
                    currentDate,
                    status: 'Active'
                }).then(() => {
                    alert("Plan purchased successfully!");
                    firebase.firestore()
                    .collection('users')
                    .doc(firebase.auth().currentUser.uid)
                    .get()
                    .then((snapshot) => {
                        const spot = snapshot.data().spot - mininvestment;
                        firebase.firestore()
                        .collection('users')
                        .doc(firebase.auth().currentUser.uid)
                        .update({
                            spot: spot
                        });
                    }).then(() => {
                        console.log('Updated successful')
                        fetchData()
                    }).catch((error) => {
                        console.log(error)
                    })
                })
            }
        } else {
            alert("Insufficient balance. Fund your wallet")
        }
    }

    const onWithdrawal = (event) => {
        if (amount > balance) {
            alert('Insufficient balance')
        } else {
            const date = new Date();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let time = date.getTime()
            let currentDate = `${day}-${month}-${year}`;
            let id = `${amount}${currentDate}${time}`
            firebase.firestore()
            .collection('withdrawal')
            .doc(firebase.auth().currentUser.uid)
            .collection('userWithdraw')
            .doc(id)
            .set({
                      amount: amount,
                      time: currentDate,
            })
            .then(() => {
                firebase.firestore()
                .collection('users')
                .doc(firebase.auth().currentUser.uid)
                .get()
                .then((snapshot) => {
                    const balance = snapshot.data().balance - amount;
                    const spot = snapshot.data().spot - amount;
                    firebase.firestore()
                    .collection('users')
                    .doc(firebase.auth().currentUser.uid)
                    .update({
                        balance: balance,
                        spot: spot
                    });
                }).then(() => {
                    console.log('Updated successful');
                    setIsWithdrawalModalVisible(false);
                }).catch((error) => {
                    console.log(error)
                })
                alert('Withdrawal request sent'); 
            })
        }
        event.preventDefault();
    }

    

    return(
        <div className='body'>
            <Container fluid>
                <Row>
                    <Col xs={12} md={3} lg={2} className="dashboard-navigation sticky-nav">
                        <div className=''></div>
                        <div className='logo-div'><img src={logo} alt="Logo" className='logo'></img><span className='logo-text'></span></div>
                        <div className='navigation-links'>
                            <Nav.Link as={NavLink} to='/' className='side-nav-link' end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={dashboard} alt="User" className='nav-image'></img><span>Dashboard</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href="#portfolio" className='side-nav-link' end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={notes} alt="User" className='nav-image'></img><span>Portfolio</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#plans' className='side-nav-link' end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={patients} alt="User" className='nav-image'></img><span>Plans</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#wallet' className='side-nav-link' end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={wallet} alt="User" className='nav-image'></img><span>Wallet</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#' className='side-nav-link' onClick={() => {setIsWithdrawalModalVisible(true)}} end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={withdraw} alt="User" className='nav-image'></img><span>Withdraw</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#' className='side-nav-link' onClick={() => {setIsVerifyModalVisible(true)}} end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={lock} alt="User" className='nav-image' style={{height: '20px', width: '20px'}}></img><span>Verify</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link as={NavLink} to='/Settings' className='side-nav-link' end='true'>
                                <div className='navigation-link navigation-link-div'>
                                    <div className='navigation-link-inner'>
                                        
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#' className='side-nav-link' end='true' onClick={() => {firebase.auth().signOut()}}>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={logout} alt="User" className='nav-image'></img><span>Logout</span>
                                    </div>
                                </div>
                            </Nav.Link>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={10} className="dashboard-body">
                        <Header lock={lock} setIsVerifyModalVisible={setIsVerifyModalVisible}/>
                        <Row className='dashboard-row'>
                            <Col md={12} lg={8} xs={12} className="dashboard-col">
                                <div className="welcome">
                                    <Row>
                                        <Col lg={12} md={12} xs={12} className="welcome-col">
                                            <h3>Welcome {user.name}</h3>
                                            <p>You currently have {portfolioLength} asset portfolio. Check out Quantitative Strategies in the Plans</p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='next-patients-div' id='wallet'>
                                    <Row>
                                        <Col lg={8} md={8} xs={8}>
                                            <p className='patients-by-gender'><span className='border-bottom-blue'>Wal</span>lets</p>
                                        </Col>
                                        <Col lg={4} md={4} xs={4} className='wallet-col'>
                                            <button className='wallet-button' onClick={() => {setIsWalletModalVisible(true)}}><p className='patients-this'><span className='patients-month'>View Wallets</span></p></button>
                                        </Col>
                                    </Row>

                                    <Modal title=""
                                        open={isWalletModalVisible}
                                        onOk={() => {
                                            setIsWalletModalVisible(false);
                                        }}
                                        onCancel={() => {
                                            setIsWalletModalVisible(false);
                                        }} className='wallet-modal'>
                                        <div className='dash-modal-inner'>
                                            <div className='table-div'>
                                                <span>BTC</span>
                                                <p>{user.btcwallet}</p>
                                            </div>
                                            <div className='table-div'>
                                                <span>ETH</span>
                                                <p>{user.ethwallet}</p>
                                            </div>
                                            <div className='table-div'>
                                                <span>USDT</span>
                                                <p>{user.usdtwallet}</p>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <div className='stats-div'>
                                    <Row>
                                        <Col md={4} lg={4} xs={12} className="stats-col-one">
                                            <div className='one'>
                                                <Row>
                                                    <Col lg={5} md={5} xs={6}>
                                                        <div className='schedule-image-div schedule-image-div-one'>
                                                            <img src={statsperson} alt="Person" className='schedule-image'></img>
                                                        </div>
                                                    </Col>
                                                    <Col lg={7} md={7} xs={6}>
                                                        <p className='all-time'>All time <img src={arrowdown} alt="Arrow" className='arrow-down'></img></p>
                                                    </Col>
                                                </Row>
                                                <p className='number'>${user.balance}.00</p>
                                                <p className='patients'>Balance</p>
                                                {/* <p className='last-month'><img src={lossarrow} className='stats-arrow' alt='Loss Arrow'></img><span>{user.balancepercent}% in 24hrs</span></p> */}
                                            </div>
                                        </Col>
                                        <Col md={4} lg={4} xs={12} className="stats-col-two">
                                            <div className='one'>
                                                <Row>
                                                    <Col lg={6} md={6} xs={6}>
                                                        <div className='schedule-image-div schedule-image-div-two'>
                                                            <img src={calendarimage} alt="Person" className='appointment-image'></img>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} md={6} xs={6}>
                                                        <p className='all-time'>Today <img src={arrowdown} alt="Arrow" className='arrow-down'></img></p>
                                                    </Col>
                                                </Row>
                                                <p className='number'>${user.spot}.00</p>
                                                <p className='patients'>Spot</p>
                                                {/* <p className='last-month'><img src={gainarrow} className='stats-arrow' alt='Gain Arrow'></img><span>{user.spotpercent}% in 24hrs</span></p> */}
                                            </div>
                                        </Col>
                                        <Col md={4} lg={4} xs={12} className="stats-col">
                                            <div className='one'>
                                                <Row>
                                                    <Col lg={6} md={6} xs={6}>
                                                        <div className='schedule-image-div schedule-image-div-three'>
                                                            <img src={clock} alt="Person" className='waiting-image'></img>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} md={6} xs={6}>
                                                        <p className='all-time'>Today <img src={arrowdown} alt="Arrow" className='arrow-down'></img></p>
                                                    </Col>
                                                </Row>
                                                <p className='number'>${totalPortfolio}.00</p>
                                                <p className='patients'>Portfolio Total</p>
                                                {/* <p className='last-month'><img src={gainarrow} className='stats-arrow' alt='Gain Arrow'></img><span>{user.bonuspercent}% in 24hrs</span></p> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='next-patients-div' id='portfolio'>
                                    <Row>
                                        <Col lg={8} md={8} xs={8}>
                                            <p className='patients-by-gender'><span className='border-bottom-blue'>Por</span>tfolio</p>
                                        </Col>
                                        <Col lg={4} md={4} xs={4}>
                                            
                                        </Col>
                                    </Row>
                                    <div className='table-div'>
                                        <Table bordered className='table' responsive>
                                            <thead>
                                                <tr>
                                                    <th>NAME</th>
                                                    <th>AMOUNT</th>
                                                    <th>Daily ROI</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {Object.entries(portfolio).map(([key, value]) => {
                                                return(
                                                    <React.Fragment key={key}>
                                                        <tr>
                                                            <td>{value.name}</td>
                                                            <td>${value.mininvestment}</td>
                                                            <td>{value.dailyroi}%</td>
                                                            <td style={{minWidth: '150px'}}>{value.currentDate}</td>
                                                            <td>{value.status}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                                <div className='next-patients-div' id='plans'>
                                    <Row>
                                        <Col lg={8} md={8} xs={8}>
                                            <p className='patients-by-gender'><span className='border-bottom-blue'>Quanti</span>tative Strategies</p>
                                        </Col>
                                        <Col lg={4} md={4} xs={4}>
                                            
                                        </Col>
                                    </Row>
                                    <div className='table-div'>
                                        <Table bordered className='table' responsive>
                                            <thead>
                                                <tr>
                                                    <th>Plan</th>
                                                    <th>Daily ROI</th>
                                                    <th>Min Investment</th>
                                                    <th>Purchase</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {Object.entries(plans).map(([key, value]) => {
                                                return(
                                                    <React.Fragment key={key}>
                                                        <tr>
                                                            <td>{value.name}</td>
                                                            <td>{value.dailyroi}%</td>
                                                            <td className='min-investment-td'>
                                                                <button onClick={() => {const currentPrice = amountInvestedArray[key]; if(amountInvestedArray[key] > value.mininvestment) {setAmountInvestedArray((prevAmounts) => ({...prevAmounts,[key]: currentPrice - 10,}))};}} className="minus">-</button>
                                                                <span className="quantity">{amountInvestedArray[key]}</span>
                                                                <button onClick={() => {const currentPrice = amountInvestedArray[key]; setAmountInvestedArray((prevAmounts) => ({...prevAmounts,[key]: currentPrice + 10,}));}} className="plus">+</button>
                                                            </td>
                                                            <td>
                                                                <button className='purchase-button' onClick={() => {addToPortfolio(value.name, value.duration, value.dailyroi, value.weeklyroi, amountInvestedArray[key])}}>Purchase</button>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} lg={4} xs={12} className='calendar-col'>
                                <Row>
                                    <Col md={6} lg={12} xs={12}>
                                        <div className='one'>
                                            <Row>
                                                <Col lg={6} md={6} xs={6}>
                                                    <div className='schedule-image-div schedule-image-div-three'>
                                                        <img src={clock} alt="Person" className='waiting-image'></img>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} xs={6}>
                                                    <p className='all-time'>Today <img src={arrowdown} alt="Arrow" className='arrow-down'></img></p>
                                                </Col>
                                            </Row>
                                            <p className='number'>${user.invest}.00</p>
                                            <p className='patients'>Profit earned</p>
                                            {/* <p className='last-month'><img src={gainarrow} className='stats-arrow' alt='Gain Arrow'></img><span>{user.profitpercent}% in 24hrs</span></p> */}
                                        </div>
                                    </Col>
                                    <Col md={6} lg={12} xs={12}>
                                        <div className='activities-div dashboard-activities-div' id="activities">
                                            <Row>
                                                <Col md={10} lg={10} xs={10}>
                                                    <p className='patients-by-gender'><span className='border-bottom-blue'>Activ</span>ities</p>
                                                </Col>
                                                <Col md={2} lg={2} xs={2} className='notification-bell-col'>
                                                    
                                                </Col>
                                            </Row>

                                            {Object.entries(deposit).map(([key, value]) => {
                                                return(
                                                    <React.Fragment key={key}>
                                                        <Row className='notification-each'>
                                                            <Col md={2} lg={2} xs={2} className='notification-image-col'>
                                                                <img src={calendarimage} alt="Person" className='activities-icon'></img>
                                                            </Col>
                                                            <Col md={10} lg={10} xs={10} className='notification-text-col'>
                                                                <span className='notification-span'>Deposit of {value.amount} initiated on {value.date} </span>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            })}
                                            

                                            {Object.entries(portfolio).map(([key, value]) => {
                                                return(
                                                    <React.Fragment key={key}>
                                                        <Row className='notification-each'>
                                                            <Col md={2} lg={2} xs={2} className='notification-image-col'>
                                                                <img src={calendarimage} alt="Person" className='activities-icon'></img>
                                                            </Col>
                                                            <Col md={10} lg={10} xs={10} className='notification-text-col'>
                                                                <span className='notification-span'>You purchased a {value.name} plan</span>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            })}

                                            {Object.entries(withdrawals).map(([key, value]) => {
                                                return(
                                                    <React.Fragment key={key}>
                                                        <Row className='notification-each'>
                                                            <Col md={2} lg={2} xs={2} className='notification-image-col'>
                                                                <img src={calendarimage} alt="Person" className='activities-icon'></img>
                                                            </Col>
                                                            <Col md={10} lg={10} xs={10} className='notification-text-col'>
                                                                <span className='notification-span'>Pending - withdrawal of {value.amount} initiated on {value.date}, awaiting  consensus for disbursement</span>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal title=""
                open={isWithdrawalModalVisible}
                onOk={() => {
                    setIsWithdrawalModalVisible(false);
                }}
                onCancel={() => {
                    setIsWithdrawalModalVisible(false);
                }} className='withdrawal-modal'>
                <div className='withdrawal-modal-inner'>
                    <h5>Withdrawal Request</h5>
                    <form onSubmit={event => {onWithdrawal(event)}}>
                        <div className='table-div'>
                            <input placeholder='Amount' className='withdrawal-input' type='text' value={amount} onChange={event => setAmount(event.target.value)} required></input>             
                        </div>
                        <div className='table-div'>
                            <select name="currency" id="currency" className='withdrawal-input select' required>
                                <option value="bitcoin">Bitcoin</option>
                                <option value="usdt">USDT</option>
                                <option value="ethereum">Ethereum</option>
                                <option value="litecoin">Litecoin</option>
                                <option value="dogecoin">Dogecoin</option>
                            </select>             
                        </div>
                        <div className='table-div'>
                            <input placeholder='External wallet address' className='withdrawal-input' type='text' required></input> 
                        </div>
                        <div className='table-div'>
                            <button className='withdrawal-submit-button' type="submit" name='submit'>Submit</button>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal title=""
                open={isVerifyModalVisible}
                onOk={() => {
                    setIsVerifyModalVisible(false);
                }}
                onCancel={() => {
                    setIsVerifyModalVisible(false);
                }} className='withdrawal-modal'>
                <div className='withdrawal-modal-inner'>
                    <h5>Follow these steps to verify your account</h5>
                    <ol style={{paddingLeft: '0px', color: 'white', marginTop: '30px'}}>
                        <li><a href="https://sa.www4.irs.gov/secureaccess/ui/?TYPE=33554433&REALMOID=06-0006b18e-628e-1187-a229-7c2b0ad00000&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=-SM-u0ktItgVFneUJDzkQ7tjvLYXyclDooCJJ7%2bjXGjg3YC5id2x9riHE98hoVgd1BBv&TARGET=-SM-https%3a%2f%2fsa%2ewww4%2eirs%2egov%2fola%2fid--verify%2f" target="_blank" rel="noreferrer">Create an ID.me account on IRS</a></li>
                        <li>Upload the following:
                            <form className='verification-form' onSubmit={submitVerification}>
                                <div>
                                    <label htmlFor='id-front'>Front picture of ID card:</label>
                                    <input type="file" name='id-front' onChange={handleIdFrontChange} accept="image/*, image/png, image/jpg, image/jpeg" required></input>
                                </div>

                                <div>
                                    <label htmlFor='id-back'>Back picture of ID card:</label>
                                    <input type="file" name='id-back' onChange={handleIdBackChange} accept="image/*, image/png, image/jpg, image/jpeg" required></input>
                                </div>
                                <button name='submit' type="submit" className='submit-verification-button'>Submit</button>
                            </form>
                        </li>
                    </ol>
                </div>
            </Modal>

            <TawkToChat/>
        </div>
    );
}
export default Dashboard;