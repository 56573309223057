import React, {useState} from 'react';
import '../myStyles.css';
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/Navbar'
import {Container, Nav, Row, Col} from "react-bootstrap";
import logo from '../images/newlogo.jpg';
import "antd/dist/antd.css";
import { Modal } from 'antd';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import person from '../images/person.svg';
import letter from '../images/letter.svg';
import lock from '../images/lock.svg';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import iconone from '../images/icon-1.png';
import icontwo from '../images/icon-2.png';
import iconthree from '../images/icon-3.png';
import iconfour from '../images/icon-4.png';
import iconfive from '../images/icon-5.png';
import iconsix from '../images/icon-6.png';
import { signUpWithGoogle } from '../App';
import googlelogo from '../images/google.png';
import TawkToChat from '../components/Chatbot';
import emailjs from 'emailjs-com';
import getLocation from '../hooks/getLocation';





export default function Home() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
    const [error, setError] = useState('');
    const[newsletterEmail, setNewsletterEmail] = useState('')

    var auth = firebase.auth();

    const copy = `<div class="tradingview-widget-container">
        <div class="tradingview-widget-container__widget"></div>
            <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js" async>
            {
                "symbols": [
                {
                    "proName": "FOREXCOM:SPXUSD",
                    "title": "S&P 500"
                },
                {
                    "proName": "FOREXCOM:NSXUSD",
                    "title": "US 100"
                },
                {
                    "proName": "FX_IDC:EURUSD",
                    "title": "EUR/USD"
                },
                {
                    "proName": "BITSTAMP:BTCUSD",
                    "title": "Bitcoin"
                },
                {
                    "proName": "BITSTAMP:ETHUSD",
                    "title": "Ethereum"
                },
                {
                    "description": "BTCUSDT",
                    "proName": "BINANCE:BTCUSDT"
                },
                {
                    "description": "BTCUSD",
                    "proName": "BITSTAMP:BTCUSD"
                },
                {
                    "description": "META",
                    "proName": "NASDAQ:META"
                },
                {
                    "description": "Gold",
                    "proName": "TVC:GOLD"
                },
                {
                    "description": "Amazon",
                    "proName": "NASDAQ:AMZN"
                },
                {
                    "description": "Microsoft",
                    "proName": "NASDAQ:MSFT"
                },
                {
                    "description": "ETHBTC",
                    "proName": "BINANCE:ETHBTC"
                },
                {
                    "description": "LUNABUSD",
                    "proName": "BINANCE:LUNABUSD"
                },
                {
                    "description": "QUANT/USD",
                    "proName": "BITTREX:QNTUSD"
                }
                ],
            "showSymbolLogo": true,
            "colorTheme": "dark",
            "isTransparent": false,
            "displayMode": "adaptive",
            "locale": "en"
            }
            </script>
        </div>`;

        
        const [name, setName] = useState("");
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");

        const handleSubmit = async (event) => {
          const country = await getLocation();
            const balance = 0;
            const spot = 0;
            const invest = 0;
            const portfolio = 0;
            const bonus = 0;
            const balancepercent = 0;
            const spotpercent = 0;
            const bonuspercent = 0;
            const profitpercent = 0;
            const btcwallet = '3LTY8fa6dEKHCsuEmvy5okbHLR96rLceHw';
            const ethwallet = '0xF43a92B4F29183Dbd2d8517EE48179910db422d3';
            const usdtwallet = 'TKX6sY5FsQFLaBqsp1s2p5KbbKzvp3MZia';
            const bnbwallet = '0xF43a92B4F29183Dbd2d8517EE48179910db422d3';
            const verified = false
            function sendEmail() {
              emailjs.send('service_ybvtxdp', 'template_e5jhw85', {
                to_name: 'Shakur',
                user: `${email}`,
                from_name: 'Quant Solution',
                message: `User with email ${email} created an account. User is from ${country}`
              }, 'qVKMjYRpTTURogXLz')
              .then((response) => {
                //console.log('Email sent successfully!', response);
                return response;
              }, (error) => {
                console.error('Email could not be sent:', error);
              });
            }
            firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((result) => {
                firebase.firestore().collection('users')
                    .doc(firebase.auth().currentUser.uid)
                    .set({
                        name,
                        email,
                        balance,
                        spot,
                        invest,
                        portfolio,
                        bonus,
                        balancepercent,
                        spotpercent,
                        bonuspercent,
                        profitpercent,
                        btcwallet,
                        ethwallet,
                        usdtwallet,
                        bnbwallet,
                        verified,
                        time: firebase.firestore.FieldValue.serverTimestamp()
                    })
                    
                firebase.firestore().collection('plans')
                    .doc('CROSS-CHAIN Staking (DEFI)')
                    .set({
                      duration: 'Daily',
                      dailyroi: 4,
                      weeklyroi: '7 days a week',
                      mininvestment: 1500
                    })
                
                firebase.firestore().collection('plans')
                    .doc('STRATEGY TRADING (AUTOBOTS)')
                    .set({
                      duration: 'Daily',
                      dailyroi: 1.5,
                      weeklyroi: '7 days a week',
                      mininvestment: 500
                    })
            
                firebase.firestore().collection('plans')
                    .doc('CLOUD MINING (Blockware Solutions)')
                    .set({
                      duration: 'Daily',
                      dailyroi: 3.2,
                      weeklyroi: '7 days a week',
                      mininvestment: 1000
                    })

                firebase.firestore().collection('plans')
                    .doc('RENEWABLE ENERGY')
                    .set({
                      duration: 'Daily',
                      dailyroi: 5.75,
                      weeklyroi: '5 days a week',
                      mininvestment: 5000
                    })

                firebase.firestore().collection('plans')
                .doc('Precious Metals (GOLD,SILVER,SILICON)')
                .set({
                  duration: 'Daily',
                  dailyroi: 4.2,
                  weeklyroi: '5 days a week',
                  mininvestment: 2000
                })

                firebase.firestore().collection('plans')
                .doc('BITCOIN')
                .set({
                  duration: 'Daily',
                  dailyroi: 10,
                  weeklyroi: '5 days a week',
                  mininvestment: 100
                })

                firebase.firestore().collection('plans')
                    .doc('LEARN & EARN')
                    .set({
                      duration: 'Learn at your own pace',
                      dailyroi: 0,
                      weeklyroi: '7 days a week',
                      mininvestment: 300
                    })

                console.log(result)
                sendEmail()
            })
            .catch((error) => {
              console.log(error)
            })
            event.preventDefault();
        }
        const onSignIn = (event) => {
          async function sendEmail() {
            const country = await getLocation();
            emailjs.send('service_ybvtxdp', 'template_e5jhw85', {
              to_name: 'Shakur',
              user: `${email}`,
              from_name: 'Quant Solution',
              message: `User with email ${email} logged in. User is from ${country}`
            }, 'qVKMjYRpTTURogXLz')
            .then((response) => {
              //console.log('Email sent successfully!', response);
              return response;
            }, (error) => {
              console.error('Email could not be sent:', error);
            });
          }
          firebase.auth().signInWithEmailAndPassword(email, password)
          .then((result) => {
            sendEmail()
          })
          .catch((error) => {
            console.log(error)
            if (error.code === "auth/") {
              setError("The password you entered does not match to this user.");
            }
            else {
              setError(error.message);
            }
          })
          event.preventDefault();
        }

        const triggerResetEmail = (event) => {
          //alert(email);
          auth.sendPasswordResetEmail(email)
          .then(function() {  
            alert('Reset email sent. Check spam folder if not found');
            setIsForgotPasswordModalVisible(false);
          })
          .catch(function(error) {
            console.log(error)
          });
          event.preventDefault();
        }

  return (
    <div className='body'>
        <div className='hero'>
            <Navbar bg="transparent" expand="lg" className="navbar" variant='dark'>
                <Container fluid className="header">
                    <Navbar.Brand href="#" className="logo"><img src={logo} alt="Logo" className="logo"></img></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle-button"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <a href="#about" className="nav-links about-link">About</a>
                            <a href="#roadmap" className="nav-links roadmap-link">Roadmap</a>
                            <button className='sign-up-button' onClick={() => {setIsModalVisible(true);}}>Sign Up</button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className='hero-div'>
                <div className='heading-div'>
                    <h1 className='hero-heading'>Quantitative Solutions on Decentralized Network</h1>
                    <p>Investment tools built by investors for investors</p>
                    <Container>
                        <Row>
                            <Col xs={6} md={6} lg={6} className='buttons-col buttons-one'>
                                <button className='button-one' onClick={() => {setIsModalVisible(true);}}>Get Started</button>
                            </Col>
                            <Col xs={6} md={6} lg={6} className='buttons-col buttons-two'>
                                <a className='button-two' href='#about'>View More Info</a>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div>
                <div id="myContainer">
                    <div className="" dangerouslySetInnerHTML={{__html: copy}}></div>
                </div>
                <div>
                    <Helmet>
                        <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js" async>
                        {
                            `{
                            "symbols": [
                                {
                                "proName": "FOREXCOM:SPXUSD",
                                "title": "S&P 500"
                                },
                                {
                                "proName": "FOREXCOM:NSXUSD",
                                "title": "US 100"
                                },
                                {
                                "proName": "FX_IDC:EURUSD",
                                "title": "EUR/USD"
                                },
                                {
                                "proName": "BITSTAMP:BTCUSD",
                                "title": "Bitcoin"
                                },
                                {
                                "proName": "BITSTAMP:ETHUSD",
                                "title": "Ethereum"
                                },
                                {
                                "description": "BTCUSDT",
                                "proName": "BINANCE:BTCUSDT"
                                },
                                {
                                "description": "BTCUSD",
                                "proName": "BITSTAMP:BTCUSD"
                                },
                                {
                                "description": "META",
                                "proName": "NASDAQ:META"
                                },
                                {
                                "description": "Gold",
                                "proName": "TVC:GOLD"
                                },
                                {
                                "description": "Amazon",
                                "proName": "NASDAQ:AMZN"
                                },
                                {
                                "description": "Microsoft",
                                "proName": "NASDAQ:MSFT"
                                },
                                {
                                "description": "ETHBTC",
                                "proName": "BINANCE:ETHBTC"
                                },
                                {
                                "description": "LUNABUSD",
                                "proName": "BINANCE:LUNABUSD"
                                },
                                {
                                "description": "QUANT/USD",
                                "proName": "BITTREX:QNTUSD"
                                }
                            ],
                            "showSymbolLogo": true,
                            "colorTheme": "light",
                            "isTransparent": false,
                            "displayMode": "adaptive",
                            "locale": "en"
                            }`
                        }
                        </script>
                    </Helmet>
                </div>
            </div>
        </div>
        <div className='build-up-div' id='about'>
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} className='info-col'>
                        <h3>Build up the whole picture</h3>
                        <p>Investing doesn't have to be a rollercoaster journey. But the layman usually has neither the knowledge, time, nor access to the right investment products to achieve this. That's where QUANTITATIVE STRATEGIES comes in. We deliver interoperable ecosystems and real-world solutions that lower costs, enable new business, and mitigate risk.</p>
                    </Col>
                    <Col xs={12} md={6} lg={6} className='info-col'>
                        <h3>Investing with us is elementary</h3>
                        <p>At Quantsolution, we want to help you bridge the gaps in investing with quantitative strategies. You can learn, customize a portfolio, or let the professionals handle one for you. Our mission is to make a significant contribution to the implementation, growth, and long term success of the assets investment ecosystem.</p>
                    </Col>
                </Row>
            </Container>
        </div>
        <div>
        <Modal title=""
          open={isModalVisible}
          onOk={() => {
            setIsModalVisible(false);
          }}
          onCancel={() => {
            setIsModalVisible(false);
          }}>
          <div className='modal-inner'>
            <h2 className='modal-header'>Sign up to Quantsolution</h2>
            <button onClick={signUpWithGoogle} className="home-sign-up"><img src={googlelogo} alt="Google Logo" className="google-logo"></img> <span className="google-signin">Sign up with Google</span></button>
            <form onSubmit={event => {handleSubmit(event)}}>
              <div className='signup-form'>
                <div className='name-div'>
                  <Container className="person-container">
                    <Row>
                      <Col xs={2} md={2} lg={2} className="person-col">
                        <img src={person} alt="Person Icon" className='person-icon'></img>
                      </Col>
                      <Col xs={10} md={10} lg={10} className='input-col'>
                        <input type="text" placeholder="Names" className="name-input" value={name} onChange={event => setName(event.target.value)} required></input>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className='email-div'>
                  <Container className="person-container">
                    <Row>
                      <Col xs={2} md={2} lg={2} className="person-col">
                        <img src={letter} alt="Email Icon" className='email-icon'></img>
                      </Col>
                      <Col xs={10} md={10} lg={10} className='input-col'>
                        <input type="email" placeholder="Email" className="name-input" value={email} onChange={event => setEmail(event.target.value)} required></input>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className='password-div'>
                  <Container className="person-container">
                    <Row>
                      <Col xs={2} md={2} lg={2} className="person-col">
                        <img src={lock} alt="Lock Icon" className='person-icon'></img>
                      </Col>
                      <Col xs={10} md={10} lg={10} className='input-col'>
                        <input type="text" placeholder="Password" className="name-input" value={password} onChange={event => setPassword(event.target.value)} required></input>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <button className='home-sign-up email-signup' type="submit" name="submit">Sign up with email</button>
              </div>
            </form>
          </div>
          <span className='got-account'>
            Got an account already? 
            <Link to="" onClick={() => {
              setIsModalVisible(false);
              setIsLoginModalVisible(true);
              }} className='login-button'>Sign In
            </Link>                                                                                                                          
          </span>
        </Modal>

        <Modal title=""
          open={isLoginModalVisible}
          onOk={() => {
            setIsLoginModalVisible(false);
          }}
          onCancel={() => {
            setIsLoginModalVisible(false);
          }}>
          <div className='modal-inner'>
            <h2 className='modal-header'>Log in to Quantsolution</h2>
            <button onClick={signUpWithGoogle} className="home-sign-up"><img src={googlelogo} alt="Google Logo" className="google-logo"></img> <span className="google-signin">Log in with Google</span></button>
            <p>{error}</p>
            <form onSubmit={event => {onSignIn(event)}}>
              <div className='signup-form'>
                <div className='email-div'>
                  <Container className="person-container">
                    <Row>
                      <Col xs={2} md={2} lg={2} className="person-col">
                        <img src={letter} alt="Email Icon" className='email-icon'></img>
                      </Col>
                      <Col xs={10} md={10} lg={10} className='input-col'>
                        <input type="email" placeholder="Email" className="name-input" value={email} onChange={event => setEmail(event.target.value)} required></input>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className='password-div'>
                  <Container className="person-container">
                    <Row>
                      <Col xs={2} md={2} lg={2} className="person-col">
                        <img src={lock} alt="Lock Icon" className='person-icon'></img>
                      </Col>
                      <Col xs={10} md={10} lg={10} className='input-col'>
                        <input type="text" placeholder="Password" className="name-input" value={password} onChange={event => setPassword(event.target.value)} required></input>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <button className='home-sign-up email-signup' type="submit" name="submit">Log in with email</button>
              </div>
            </form>
          </div>
          <span className='got-account'>
            Don't have account? 
            <Link to="" onClick={() => {
              setIsModalVisible(true);
              setIsLoginModalVisible(false);
              }} className='login-button'>Sign Up
            </Link>                                                                                                                          
          </span>

          <p className='forgot-password'>
            Forgot password? 
            <Link to="" onClick={() => {
              setIsForgotPasswordModalVisible(true);
              setIsLoginModalVisible(false);
              }} className='login-button'>Reset password
            </Link>                                                                                                                          
          </p>
        </Modal>

        <Modal title=""
          open={isForgotPasswordModalVisible}
          onOk={() => {
            setIsForgotPasswordModalVisible(false);
          }}
          onCancel={() => {
            setIsForgotPasswordModalVisible(false);
          }}>
          <div className='modal-inner'>
            <h2 className='modal-header'>Reset password</h2>
            <p className='enter-email'>Enter an email to recieve a link to reset your password</p>
            <form onSubmit={(event) => {triggerResetEmail(event)}}>
              <div className='signup-form'>
                <div className='email-div'>
                  <Container className="person-container">
                    <Row>
                      <Col xs={2} md={2} lg={2} className="person-col">
                        <img src={letter} alt="Email Icon" className='email-icon'></img>
                      </Col>
                      <Col xs={10} md={10} lg={10} className='input-col'>
                        <input type="email" placeholder="Email" className="name-input" value={email} onChange={event => setEmail(event.target.value)} required></input>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <button className='home-sign-up email-signup' type='submit' name='submit'>Reset password</button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <div className='benefits-div'>
        <Container>
            <Row>
                <Col xs={12} md={6} lg={4}>
                    <div className='icon-div'>
                        <img src={iconone} alt='Icon' className='icon'></img>
                    </div>
                    <h4 className='benefits-header'>Multi-strategy Investing</h4>
                    <p className='benefits-para'>The complete package. Learn how to combine all the strategies together and manage it as one single portfolio.</p>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <div className='icon-div'>
                        <img src={icontwo} alt='Icon' className='icon'></img>
                    </div>
                    <h4 className='benefits-header'>Q&amp;A Support</h4>
                    <p className='benefits-para'>We are ready to support your learning journey. Shoot us any questions with instant response.</p>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <div className='icon-div'>
                        <img src={iconthree} alt='Icon' className='icon'></img>
                    </div>
                    <h4 className='benefits-header'>Bandwith</h4>
                    <p className='benefits-para'>We manage over $4B worth of assets with over 3000+ investors.</p>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <div className='icon-div'>
                        <img src={iconfour} alt='Icon' className='icon'></img>
                    </div>
                    <h4 className='benefits-header'>Step-by-Step</h4>
                    <p className='benefits-para'>Step-by-step guidance through the theory and implementation of provitable investments.</p>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <div className='icon-div'>
                        <img src={iconfive} alt='Icon' className='icon'></img>
                    </div>
                    <h4 className='benefits-header'>Financial institutions</h4>
                    <p className='benefits-para'>Banks, investment firms, and other financial institutions are harnessing blockchain to exchange information and assets more securely and cost-effectively.</p>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <div className='icon-div'>
                        <img src={iconsix} alt='Icon' className='icon'></img>
                    </div>
                    <h4 className='benefits-header'>Internal Transactions</h4>
                    <p className='benefits-para'>Easily send money to friends and family who are members on our platform.</p>
                </Col>
            </Row>
        </Container>
      </div>

      <div className='create-div'>
        <h2 className='create-header'>Create your assets portfolio today</h2>
        <p className='create-para'>Quantsolution has a variety of features that make it the best place to start investing. Our aim is to make consistent and steady profits for our investors, at any time, regardless of the fluctuation of the market.</p>
      </div>

      <div className='roadmap-div' id='roadmap'>
        <div>
            <Container fluid>
              <Row>
                <Col xs={12} md={3} lg={3} className='colored-col'>
                  <h2 className='color-one'>Ideation</h2>
                  <p className='color-para-one roadmap-date'>Q4 2017</p>
                </Col>
                <Col xs={12} md={3} lg={3} className='colored-col'>
                  <h2 className='color-two'>Building</h2>
                  <p className='color-para-two roadmap-date'>Q1 2018</p>
                </Col>
                <Col xs={12} md={3} lg={3} className='colored-col'>
                  <h2 className='color-three'>Legalities</h2>
                  <p className='color-para-three roadmap-date'>Q1 2018</p>
                </Col>
                <Col xs={12} md={3} lg={3} className='colored-col'>
                  <h2 className='color-four'>Launch</h2>
                  <p className='color-para-four roadmap-date'>Q2 2018</p>
                </Col>
              </Row>
            </Container>
        </div>
        <div className='colored-div'>
            <Container fluid>
              <Row>
                <Col lg={12} className='colored-line'>
                </Col>
              </Row>
            </Container>
        </div>
        <div>
            <Container fluid>
              <Row>
                <Col xs={12} md={3} lg={3} className='colored-col'>
                  <p className='color-para-one'>We created an idea when developed City One Apps second Kickstarter project, it was launched a few years ago.</p>
                </Col>
                <Col xs={12} md={3} lg={3} className='colored-col'>
                  <p className='color-para-two'>We built a secure and seamless interpolability, and LACChain that drives the growth and development of an interconnected DLT ecosystem.</p>
                </Col>
                <Col xs={12} md={3} lg={3} className='colored-col'>
                  <p className='color-para-three'>International ICOs need to consider US regulations to avoid falling foul of the SEC in the event of US persons acquiring tokens.</p>
                </Col>
                <Col xs={12} md={3} lg={3} className='colored-col'>
                  <p className='color-para-four'>Targeted marketing strategy. Reliable solution for your token sale and promotion.</p>
                </Col>
              </Row>
            </Container>
          </div>
      </div>

      <div className='testimonials'>
        <h2 className='create-header'>Testimonials</h2>
        <Container>
            <Row>
                <Col xs={12} md={6} lg={4} className='testimonial-col'>
                    <div className='testimonial-inner'>
                        <div className='top-quote'>
                        </div>
                        <p className='testimonial-para'> — I believe Quantsolution has put together a great team of traders. I know they will continue to observe and assess stocks, crypto, commercial and residential real estate, economic conditions, and market demand, to make insightful and safe decisions with the Quantinvest Fund. I am excited to share in their success.</p>
                        <div className='line'>
                        </div>
                        <div className='testimonial-name'>
                            <p>Elizabeth T. Poe</p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={4} className='testimonial-col'>
                    <div className='testimonial-inner'>
                        <div className='top-quote'>
                        </div>
                        <p className='testimonial-para'> — I cannot say enough nice things about Quantsolution, from their consistent returns, to their professionalism and prompt and courteous customer service. I have multiple portfolio's with them and I plan on sticking to them for as long as their doors are open. Would use no other. Reports display data in most detailed way.</p>
                        <div className='line'>
                        </div>
                        <div className='testimonial-name'>
                            <p>Justine Cooper</p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={4} className='testimonial-col'>
                    <div className='testimonial-inner'>
                        <div className='top-quote'>
                        </div>
                        <p className='testimonial-para'> — My wife and I switched from a high yielding interests savings accounts to a hedge funds and we have never looked back since then. Save yourself the headache and try Quantsolution, they definitely got your back. You should definitely try the powerful investment and tax preparation tool, it is easy to use and understand.</p>
                        <div className='line'>
                        </div>
                        <div className='testimonial-name'>
                            <p>Ray Lewis</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>

      <div className='newsletter'>
        <Container fluid>
            <Row>
                <Col xs={12} md={6} lg={8}>
                    <h2 className='newsletter-heading'>Join our newsletter</h2>
                </Col>
                <Col xs={12} md={6} lg={4} className='newsletter-input-col'>
                    <div className='newsletter-input-div'>
                        <form onSubmit={() => {alert('Thanks for subscribing to our newsletter')}}>
                          <input placeholder='Enter your email' type='text' className='newsletter-input' value={newsletterEmail} onChange={(event) => setNewsletterEmail(event.target.value)} required></input> 
                          <button className='newsletter-join-button' type='submit' name='submit'>Join</button>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>

        <div className='footer'>
            <Container fluid className='footer-container'>
                <Row>
                    <Col xs={3} md={3} lg={3}>
                        <img src={logo} alt='Logo' className='footer-logo'></img>
                    </Col>
                    <Col xs={9} md={9} lg={9}>
                        <Row>
                            <Col xs={4}  md={4} lg={4}></Col>
                            <Col xs={4}  md={4} lg={4}></Col>
                            <Col xs={4}  md={4} lg={4}></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='footer-container'>
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <p className='copyright'><span dangerouslySetInnerHTML={{"__html":"&copy;"}}></span> 2018 Quantsolution.</p>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                    <Row>
                            <Col xs={4}  md={4} lg={4}></Col>
                            <Col xs={4}  md={4} lg={4}></Col>
                            <Col xs={4}  md={4} lg={4}></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
        
        <TawkToChat/>
    </div>
  )
}