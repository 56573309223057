import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './views/Home';
import Dashboard from './views/users/Dashboard';
import AdminDashboard from './views/admin/Dashboard';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import emailjs from 'emailjs-com';
import getLocation from './hooks/getLocation';

const firebaseConfig = {
  apiKey: "AIzaSyCX3m_95mKo7Fy983PzDTfHqAm6jPVxRlM",
  authDomain: "quantinvestment-16b3f.firebaseapp.com",
  projectId: "quantinvestment-16b3f",
  storageBucket: "quantinvestment-16b3f.appspot.com",
  messagingSenderId: "755028068386",
  appId: "1:755028068386:web:777c37ea3d3a6cf804627b",
  measurementId: "G-5FRLB5T6MY"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signUpWithGoogle = () => {
  const balance = 0;
  const spot = 0;
  const invest = 0;
  const portfolio = 0;
  const bonus = 0;
  const balancepercent = 0;
  const spotpercent = 0;
  const bonuspercent = 0;
  const profitpercent = 0;
  const btcwallet = '3LTY8fa6dEKHCsuEmvy5okbHLR96rLceHw';
  const ethwallet = '0xF43a92B4F29183Dbd2d8517EE48179910db422d3';
  const usdtwallet = 'TKX6sY5FsQFLaBqsp1s2p5KbbKzvp3MZia';
  const bnbwallet = '0xF43a92B4F29183Dbd2d8517EE48179910db422d3';
  const verified = false
  auth.signInWithPopup(provider)
  .then((result) => {
    // The signed-in user info.
    var user = result.user;
    var useremail = user._delegate.email
    const userId = user.uid;

    async function sendEmail() {
      const country = await getLocation();
      emailjs.send('service_ybvtxdp', 'template_e5jhw85', {
        to_name: 'Shakur',
        user: `${user.name}`,
        from_name: 'Quant Solution',
        message: `User with email ${useremail} created an account. User is from ${country}`
      }, 'qVKMjYRpTTURogXLz')
      .then((response) => {
        console.log('Email sent successfully!', response);
        return response;
      }, (error) => {
        console.error('Email could not be sent:', error);
      });
    }

    async function sendLoginEmail() {
      const country = await getLocation();
      emailjs.send('service_ybvtxdp', 'template_e5jhw85', {
        to_name: 'Shakur',
        user: `${user.name}`,
        from_name: 'Quant Solution',
        message: `User with email ${useremail} logged in. User is from ${country}`
      }, 'qVKMjYRpTTURogXLz')
      .then((response) => {
        console.log('Email sent successfully!', response);
        return response;
      }, (error) => {
        console.error('Email could not be sent:', error);
      });
    }

    firebase.firestore().collection('users').doc(userId).get()
    .then((docSnapshot) => {
      if (!docSnapshot.exists) {
        firebase.firestore().collection('users')
        .doc(firebase.auth().currentUser.uid)
        .set({
          email: useremail,
          balance,
          spot,
          invest,
          portfolio,
          bonus,
          balancepercent,
          spotpercent,
          bonuspercent,
          profitpercent,
          btcwallet,
          ethwallet,
          usdtwallet,
          bnbwallet,
          verified,
          time: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
          sendEmail()
        })
      } else {
        sendLoginEmail()
      }
    })   
  })
};


export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false,
      email: '',
    }
  }

  async componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if(!user){
        this.setState({
          loggedIn: false,
        })
      } else {
        this.setState({
          loggedIn: true,
        })
        this.setState({
          email: user.email,
        })
      }
    })
  }
  
  render() {
    if(!this.state.loggedIn){
      return (
        <Router>
          <Routes>
            <Route path='/' element={<Home></Home>}></Route>
          </Routes>
        </Router>
      );
    }else {
      if(this.state.email === 'nattymaniac01@gmail.com') {
        return (
          <Router>
            <Routes>
              <Route path='/' element={<AdminDashboard/>}></Route>
            </Routes>
          </Router>
        );
      } else if (this.state.email === 'service.udefi@gmail.com') {
        return (
          <Router>
            <Routes>
              <Route path='/' element={<AdminDashboard/>}></Route>
            </Routes>
          </Router>
        );
      } else {
        return (
          <Router>
            <Routes>
              <Route path='/' element={<Dashboard/>}></Route>
            </Routes>
          </Router>
        );
      }
    }
  }
}