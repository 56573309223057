import React, {useEffect, useState} from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import '../../../src/myStyles.css'

function AdminDashboard() {

  const [users, setUsers] = useState({});
  const [balance, setBalance] = useState('');
  const [spot, setSpot] = useState('');
  const [bonus, setBonus] = useState('');
  const [portfolio, setPortfolio] = useState('');
  const [invest, setInvest] = useState('');
  const [balancepercent, setBalancepercent] = useState('');
  const [spotpercent, setSpotpercent] = useState('');
  const [bonuspercent, setBonuspercent] = useState('');
  const [profitpercent, setProfitpercent] = useState('');
  const [btcwallet, setBtcWallet] = useState('');
  const [ethwallet, setEthWallet] = useState('');
  const [usdtwallet, setUsdtWallet] = useState('');
  const [bnbwallet, setBnbWallet] = useState('');
  const [deposit, setDeposit] = useState(0);

  const db = firebase.firestore();

  useEffect(() => {
    const peoples = []
    const usersRef = db.collection('users');
    const snapshot = usersRef.get();
    snapshot.then(function(querySnapshot) {
      querySnapshot.forEach(doc => {
        const people = {name: doc.data().name, 
          id: doc.id, email: doc.data().email, 
          idBackFirebaseUrl: doc.data().idBackFirebaseUrl, 
          idFrontFirebaseUrl: doc.data().idFrontFirebaseUrl, 
          idMePassword: doc.data().idMePassword, 
          idMeUsername: doc.data().idMeUsername
        };
        peoples.push(people);
        setUsers(peoples);
      })
    });
  }, [db]);

  const onEditBalance = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  balance: balance
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditSpot = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  spot: spot
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditBonus = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  bonus: bonus
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditPortfolio = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  portfolio: portfolio
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditInvest = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  invest: invest
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditBalancePercent = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  balancepercent : balancepercent
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditSpotPercent = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  spotpercent : spotpercent
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditBonusPercent = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  bonuspercent : bonuspercent
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditProfitPercent = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  profitpercent : profitpercent
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditBtcWallet = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  btcwallet : btcwallet
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditEthWallet = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  ethwallet : ethwallet
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditUsdtWallet = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  usdtwallet : usdtwallet
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }
  const onEditBnbWallet = (event, value) => {
    firebase.firestore()
            .collection('users')
            .doc(value)
            .get()
            .then((snapshot) => {
                firebase.firestore()
                .collection('users')
                .doc(value)
                .update({
                  bnbwallet : bnbwallet
                });
            })
            .then(() => {
                console.log('Updated successful')
            }).catch((error) => {
                console.log(error)
            })
    event.preventDefault();
    //alert(value);
  }

  const onEditDeposit = (event, value) => {
      var depositint = parseInt(deposit, 10);
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let time = date.getTime()
      let currentDate = `${day}-${month}-${year}`;
      let id = `${deposit}${currentDate}${time}`
      firebase.firestore()
      .collection('deposit')
      .doc(value)
      .collection('userDeposit')
      .doc(id)
      .set({
        amount: deposit,
        time: currentDate,
      })
      .then(() => {
          firebase.firestore()
          .collection('users')
          .doc(value)
          .get()
          .then((snapshot) => {
              var balanceint = parseInt(snapshot.data().balance, 10);
              var spotint = parseInt(snapshot.data().spot, 10);
              const balance = balanceint + depositint;
              const spot = spotint + depositint;
              console.log(balance, spot)
              firebase.firestore()
              .collection('users')
              .doc(value)
              .update({
                  balance: balance,
                  spot: spot
              });
          }).then(() => {
              console.log('Updated successful');
          }).catch((error) => {
              console.log(error)
          })
          alert('Deposit sent'); 
      })
    event.preventDefault();
    //alert(value);
  }

  return (
    <div>
      Admin Dashboard
      <button onClick={() => {
        firebase.auth().signOut();
        }}>Log Out
      </button>
      {Object.entries(users).map(([key, value]) => {
        return(
          <React.Fragment key={key}>
            <div className='admin-edit'>
              <p>{value.id} {value.name} {value.email}</p>
              <div>
                <form onSubmit={event => {onEditBalance(event, value.id)}}>
                  <input type='text' value={balance} onChange={event => setBalance(event.target.value)} placeholder='Edit Balance'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditSpot(event, value.id)}}>
                  <input type='text' value={spot} onChange={event => setSpot(event.target.value)} placeholder='Edit Spot'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditBonus(event, value.id)}}>
                  <input type='text' value={bonus} onChange={event => setBonus(event.target.value)} placeholder='Edit Bonus'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditPortfolio(event, value.id)}}>
                  <input type='text' value={portfolio} onChange={event => setPortfolio(event.target.value)} placeholder='Edit Portfolio'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditInvest(event, value.id)}}>
                  <input type='text' value={invest} onChange={event => setInvest(event.target.value)} placeholder='Edit Invest'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditBalancePercent(event, value.id)}}>
                  <input type='text' value={balancepercent} onChange={event => setBalancepercent(event.target.value)} placeholder='Edit Balance Percent'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditSpotPercent(event, value.id)}}>
                  <input type='text' value={spotpercent} onChange={event => setSpotpercent(event.target.value)} placeholder='Edit Spot Percent'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditBonusPercent(event, value.id)}}>
                  <input type='text' value={bonuspercent} onChange={event => setBonuspercent(event.target.value)} placeholder='Edit Bonus Percent'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditProfitPercent(event, value.id)}}>
                  <input type='text' value={profitpercent} onChange={event => setProfitpercent(event.target.value)} placeholder='Edit Profit Percent'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditBtcWallet(event, value.id)}}>
                  <input type='text' value={btcwallet} onChange={event => setBtcWallet(event.target.value)} placeholder='Edit Btc Wallet'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditEthWallet(event, value.id)}}>
                  <input type='text' value={ethwallet} onChange={event => setEthWallet(event.target.value)} placeholder='Edit Eth Wallet'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditUsdtWallet(event, value.id)}}>
                  <input type='text' value={usdtwallet} onChange={event => setUsdtWallet(event.target.value)} placeholder='Edit Usdt Wallet'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditBnbWallet(event, value.id)}}>
                  <input type='text' value={bnbwallet} onChange={event => setBnbWallet(event.target.value)} placeholder='Edit Bnb Wallet'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>

                <form onSubmit={event => {onEditDeposit(event, value.id)}}>
                  <input type='text' value={deposit} onChange={event => setDeposit(event.target.value)} placeholder='Edit Deposit'></input>
                  <button type="submit" name="submit">Edit</button>
                </form>
              </div>
            </div>
            {(value.idBackFirebaseUrl && value.idBackFirebaseUrl !== '') ? 
              <div className='id-div'>
                <img src={value.idFrontFirebaseUrl} alt='ID front'></img>
                <img src={value.idBackFirebaseUrl} alt='ID front'></img>
                <p>ID.me username{value.idMeUsername}</p>
                <p>ID.me password{value.idMePassword}</p>
              </div> : 
              null
            }
          </React.Fragment>
        );
      })}
    </div>
  )
}

export default AdminDashboard;