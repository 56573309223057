import React, { useEffect } from 'react';

const TawkToChat = () => {
  useEffect(() => {
    // Check if the script already exists
    if (document.getElementById('tawkto-script')) return;

    const script = document.createElement('script');
    script.id = 'tawkto-script';
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://embed.tawk.to/665c37519a809f19fb37dfab/1hvc3g66a';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    const scriptLoadStart = document.createElement('script');
    scriptLoadStart.type = 'text/javascript';
    scriptLoadStart.text = `
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    `;

    document.head.appendChild(scriptLoadStart);
    document.head.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
      if (scriptLoadStart.parentNode) {
        scriptLoadStart.parentNode.removeChild(scriptLoadStart);
      }
    };
  }, []);

  return null; // This component doesn't render anything in the DOM
};

export default TawkToChat;