import React, {useState, useEffect, useRef} from 'react';
import person from './Images/person.png';
import notification from './Images/bell.png';
import './myStyles.css';
import {Navbar, Nav, Container} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import dashboard from './Images/dashboard.png';
import patients from './Images/patients.png';
import notes from './Images/notes.png';
import logout from '../images/logout.png';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import wallet from './Images/wallet.png';
import withdraw from '../images/withdraw.png';
import "antd/dist/antd.css";
import { Modal } from 'antd';

function Header({lock, setIsVerifyModalVisible}) {
    const [isWithdrawalModalVisible, setIsWithdrawalModalVisible] = useState(false);
    const [amount, setAmount] = useState(0);
    const [balance, setUserBalance] = useState(0);
    const [user, setUser] = useState([]);
    const toggleRef = useRef(null)


    useEffect(() => {
        firebase.firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then((snapshot) => {
        if(snapshot.exists){
            //console.log(snapshot.data());
            setUser(snapshot.data());
            //console.log(user);
            setUserBalance(user.balance)
        } else {
            console.log('does not exist')
        }
        })
    })

    const onWithdrawal = (event) => {
        if (amount > balance) {
            alert('Insufficient balance')
        } else {
            const date = new Date();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let time = date.getTime()
            let currentDate = `${day}-${month}-${year}`;
            let id = `${amount}${currentDate}${time}`
            firebase.firestore()
            .collection('withdrawal')
            .doc(firebase.auth().currentUser.uid)
            .collection('userWithdraw')
            .doc(id)
            .set({
                      amount: amount,
                      time: currentDate,
            })
            .then(() => {
                firebase.firestore()
                .collection('users')
                .doc(firebase.auth().currentUser.uid)
                .get()
                .then((snapshot) => {
                    const balance = snapshot.data().balance - amount;
                    const spot = snapshot.data().spot - amount;
                    firebase.firestore()
                    .collection('users')
                    .doc(firebase.auth().currentUser.uid)
                    .update({
                        balance: balance,
                        spot: spot
                    });
                }).then(() => {
                    console.log('Updated successful');
                    setIsWithdrawalModalVisible(false);
                }).catch((error) => {
                    console.log(error)
                })
                alert('Withdrawal request sent'); 
            })
        }
        event.preventDefault();
    }

    return(
        <div className='dash-header sticky'>
            <div className='header-inner'>
                <a href="#activities">
                    <img src={notification} alt="Notification Icon" className='header-notification'></img>
                </a>
                <a href='#'>
                    <img src={person} alt="User" className='header-image'></img>
                </a>
            </div>
            <Navbar bg="transparent" expand="lg" className="dashboard-navbar" variant='dark'>
                <Container fluid className="dashboard-header">
                    <Navbar.Brand href="#" className="logo"></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle-button" ref={toggleRef}/>
                    <Navbar.Collapse id="basic-navbar-nav" className='dashboard-navbar-collapse'>
                        <Nav className="ms-auto">
                        <div className='navigation-links'>
                            <Nav.Link as={NavLink} to='/' className='side-nav-link' end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={dashboard} alt="User" className='nav-image'></img><span>Dashboard</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href="#portfolio" className='side-nav-link' end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={notes} alt="User" className='nav-image'></img><span>Portfolio</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#plans' className='side-nav-link' end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={patients} alt="User" className='nav-image'></img><span>Plans</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#wallet' className='side-nav-link' end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={wallet} alt="User" className='nav-image'></img><span>Wallet</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#' className='side-nav-link' onClick={() => {toggleRef.current.click(); setIsWithdrawalModalVisible(true)}} end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={withdraw} alt="User" className='nav-image'></img><span>Withdraw</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#' className='side-nav-link' onClick={() => {toggleRef.current.click(); setIsVerifyModalVisible(true)}} end='true'>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={lock} alt="User" className='nav-image' style={{height: '20px', width: '20px'}}></img><span>Verify</span>
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link as={NavLink} to='/Settings' className='side-nav-link' end='true'>
                                <div className='navigation-link navigation-link-div'>
                                    <div className='navigation-link-inner'>
                                        
                                    </div>
                                </div>
                            </Nav.Link>
                            <Nav.Link href='#' className='side-nav-link' end='true' onClick={() => {firebase.auth().signOut()}}>
                                <div className='navigation-link'>
                                    <div className='navigation-link-inner'>
                                        <img src={logout} alt="User" className='nav-image'></img><span>Logout</span>
                                    </div>
                                </div>
                            </Nav.Link>
                        </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Modal title=""
                open={isWithdrawalModalVisible}
                onOk={() => {
                    setIsWithdrawalModalVisible(false);
                }}
                onCancel={() => {
                                setIsWithdrawalModalVisible(false);
                }} className='withdrawal-modal'>
                            <div className='withdrawal-modal-inner'>
                                <h5>Withdrawal Request</h5>
                                <form onSubmit={event => {onWithdrawal(event)}}>
                                    <div className='table-div'>
                                        <input placeholder='Amount' className='withdrawal-input' type='text' value={amount} onChange={event => setAmount(event.target.value)} required></input>             
                                    </div>
                                    <div className='table-div'>
                                        <select name="currency" id="currency" className='withdrawal-input select' required>
                                            <option value="bitcoin">Bitcoin</option>
                                            <option value="usdt">USDT</option>
                                            <option value="ethereum">Ethereum</option>
                                            <option value="litecoin">Litecoin</option>
                                            <option value="dogecoin">Dogecoin</option>
                                        </select>             
                                    </div>
                                    <div className='table-div'>
                                        <input placeholder='External wallet address' className='withdrawal-input' type='text' required></input>             
                                    </div>
                                    <div className='table-div'>
                                        <button className='withdrawal-submit-button' type="submit" name='submit'>Submit</button>
                                    </div>
                                </form>
                            </div>
            </Modal>
        </div>
    );
}
export default Header;